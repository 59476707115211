<template>
  <form>
    <v-row>
      <v-col cols="4" sm="4" md="3" lg="3" class="pb-0">
        <v-subheader>Name</v-subheader>
      </v-col>
      <v-col cols="8" sm="8" md="6" lg="6" class="pb-0">
        <v-text-field outlined flat dense background-color="white"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
        <v-subheader>Despreciation Method</v-subheader>
      </v-col>
      <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
        <v-select outlined flat dense background-color="white"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
        <v-subheader>Estimated Life</v-subheader>
      </v-col>
      <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
        <v-text-field outlined flat dense background-color="white" suffix="year"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
        <v-subheader>Depreciation Rate</v-subheader>
      </v-col>
      <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
        0 %
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {};
</script>

<style></style>
